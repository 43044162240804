/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import LayoutWithAuth from '../components/layoutWithAuth';
import PageTransition from '../components/PageTransition/index';
import { Box } from '../style/basicStyle';
import colors from '../utils/colors';

const Shop = ({ toggleNavDark, location }) => {
  return (
    <PageTransition location={location}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>Online Shop</title>
        </Helmet>
        <Box bg={colors.primary}>
          <Box fontSize={[2]} bg={colors.primary}>
            <Box width={[1, 1, 1 / 2]} m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']} px={[3, 3, 0]} color={colors.secondary}>
              <h1>BULLDOGS POP UP STORE</h1>
              <h2>New and Exciting Items that are here for a Limited Time.</h2>
              <p>
                <b>The store will be open until May 14th.</b>
              </p>
              <p>
                After the Pop Up Shop closes our Regular Bulldogs Store will open back up with Bulldogs Apparel that the POP UP STORE does not have. Do not Worry about what is not there now, it will be in our Store after the shop closes. The items in the Pop Up shop WILL NOT be available in the regular store, so don’t miss out on ordering them here.
              </p>
              <p>
                <b>Clicking the link below will take you to the Pop-Up Store:</b>
              </p>
              <Box>
                <b>
                  <a style={{ fontSize: '32px', textDecoration: 'underline' }} target="_blank" rel="noreferrer" href="https://xjerseys.us/collections/florida-bulldogs">Florida Bulldogs Pop-up Shop</a>
                </b>
              </Box>
              <h3>Any Questions on Number Availability, or items please reach out to Coach Alexis at 480-882-8391. You can text as well. </h3>
            </Box>
          </Box>
        </Box>
      </LayoutWithAuth>
    </PageTransition>
  );
};
export default Shop;
